import { useEffect, useState } from 'react'; 
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { usePrisContext } from "./context/AppContext";
import MainApi from "./api/mainApi";
import SaleConfirmApi from "./api/saleConfirmApi";
import Header from "./components/common/Header";
import Footer from "./components/common/Footer";

export default function PaymentSuccess(props) {
 	const { contextDavaData } = usePrisContext();
   const [ErrorMSg, setErrorMSg] = useState('');
 
   useEffect(() => {
		// Sale confirm ation API call function
		async function saleConfirmApi() {
			const items = JSON.parse(localStorage.getItem('items'));
			var AuthToken = items.AuthToken;
			var externalId = items.externalId;
			var ProfitStream = items.ProfitStream;
			const payloadConfirm = await SaleConfirmApi({"send_confirmation": true}, `${process.env.REACT_APP_API_BASE_URL}api/core/sales/v4/${externalId}/confirm`, `${AuthToken}`);
			if (payloadConfirm?.status == 204) {
				if (ProfitStream && ProfitStream !== undefined && ProfitStream !='') {
					hitApi();
				}else{
					localStorage.removeItem('items');
					window.location.href='https://fairpris.dk/el/tak-for-at-blive-el-kunde/';
				}
			} else {
				setErrorMSg({ msg: 'Something went wrong, please try again', type: 'error' });
			}
		}

		async function hitApi() {
			const items = JSON.parse(localStorage.getItem('items'));
 			var AuthToken = items.AuthToken;
			var externalId = items.externalId;
			var productionSaleOptions = items.saleData;
		 // sale v3 api
			var payloadSale = await MainApi(productionSaleOptions, `${process.env.REACT_APP_API_BASE_URL}api/sale/v4`, AuthToken);
				if (payloadSale.status == 200) {
					localStorage.removeItem('items');
					window.location.href='https://fairpris.dk/el/tak-for-at-blive-el-kunde/';
				}else{
					setErrorMSg({ msg: 'Something went wrong, please try again', type: 'error' });
				}
		}
 		saleConfirmApi();
	}, []);

	return (
        <Row className=''>
            <Col md={12} className="d-flex flex-column justify-content-between">
			{ErrorMSg ?
                  <p className={`alert alert-${ErrorMSg.type === 'success' ? 'success' : 'danger'}`}>{ErrorMSg ? ErrorMSg?.msg : null}</p>
                : null}
             </Col>
        </Row>
    );
}
